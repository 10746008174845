/* eslint react/prop-types: 0, react/display-name: 0 */
import { LocaleProvider } from "@hellodarwin/core/web/features/providers/locale-provider";
import PlatformProvider from "@hellodarwin/core/web/features/providers/platform-provider";
import { TranslationsProvider } from "@hellodarwin/core/web/features/providers/translations-provider";
import { ThemeProvider } from "@hellodarwin/core/web/plugins/styled";
import { GlobalTypographyStyling } from "@hellodarwin/core/web/theme/global-style";
import ConfigProvider from "antd/es/config-provider";
import { GatsbyBrowser } from "gatsby";
import React from "react";
import PaginationProvider from "./src/context/PaginationProvider";
import { RootProvider } from "./src/context/RootProvider";
import URLParamProvider from "./src/context/URLParamProvider";
import getAntdTheme from "./src/utils/styles/antd-theme";
import GlobalStyle from "./src/utils/styles/global";
import theme from "./src/utils/styles/theme";

const WrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  const antdTheme = getAntdTheme(theme);

  return (
    <div id="root">
      <ThemeProvider theme={theme}>
        <ConfigProvider theme={antdTheme}>
          <PlatformProvider app="website" contentfulClient={undefined}>
            <LocaleProvider>
              <RootProvider>
                <TranslationsProvider>
                  <PaginationProvider>
                    <URLParamProvider>
                      <GlobalStyle />
                      <GlobalTypographyStyling />
                      {element}
                    </URLParamProvider>
                  </PaginationProvider>
                </TranslationsProvider>
              </RootProvider>
            </LocaleProvider>
          </PlatformProvider>
        </ConfigProvider>
      </ThemeProvider>
    </div>
  );
};

export default WrapRootElement;

