import styled, {
    css,
    DefaultTheme,
} from "@hellodarwin/core/web/plugins/styled";
import {
    ContainerContentMarginStylingProps,
    ContainerContentStyledProps,
    ContainerContentWidthStylingProps,
    ContainerStyledProps,
} from "./types";

export const ContainerContentMarginStyling = ({
  theme,
}: ContainerContentMarginStylingProps) => css`
  padding-left: 0;
  padding-right: 0;

  padding-top: 45px;
  padding-bottom: 45px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    padding-top: 70px;
    padding-bottom: 54px;
  }
`;

export const ContainerContentWidthStyling = ({
  theme,
  $cap,
}: ContainerContentWidthStylingProps) => css`
  width: calc(100% - 48px);
  margin: 0 auto;
  max-width: 450px;
  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: calc(100% - 200px);
    max-width: calc(${$cap}px - 300px);
  }

  @media (min-width: ${theme.breakpoints.l}px) {
    width: 100%;
    max-width: ${$cap}px;
  }
`;

export const ContainerContentStyled = styled.section<ContainerContentStyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: fit-content;

  li {
    width: fit-content;
    .hd-text {
      width: fit-content;
      display: inline;
    }
  }

  ${({ theme, $noPadding, $fromRoot }) =>
    !$noPadding && $fromRoot
      ? ContainerContentMarginStyling({ theme })
      : undefined}
  ${({ theme, $fullWidth, $cap, $noPadding, $fromRoot }) =>
    !$noPadding && $cap !== "auto" && $fromRoot
      ? ContainerContentWidthStyling({ theme, $cap, $fullWidth })
      : `width:100%;`}
`;

export const GradientStyling = (theme: DefaultTheme, size: number) => css`
  &:after,
  &:before {
    content: "";
    position: absolute;

    left: 0;
    width: 100%;
  }
  &:before {
    top: 0;
    height: ${size}%;
    background: ${theme.colors.beige_1};
  }
  &:after {
    top: ${size}%;
    height: ${100 - size}%;
    background: ${theme.colors.beige_2};
  }
`;
export const ContainerStyled = styled.div<ContainerStyledProps>`
  position: relative;
  z-index: 1;
  width: 100%;
  ${({ $backgroundColor, theme }) =>
    !!$backgroundColor
      ? $backgroundColor === "gradient"
        ? GradientStyling(theme, 70)
        : css`
            background: ${$backgroundColor};
          `
      : undefined};
`;

